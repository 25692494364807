<!--echarts-Tree-组件-->
<template>
  <div id="echartsTreeModule">
      <div :id="echartsId" :style="style" />
  </div>
</template>

<script>
import { EleResize } from '../../../utils/esresize'
export default {
    props:['data','echartsId','time'],
    data(){
        return{
            style:{ width: '100%', height: '100%' },
            myChart:null,
            option:{
                tooltip: {
                    trigger: 'item',
                    triggerOn: 'mousemove'
                },
                series: [
                    {
                        type: 'tree',
                        initialTreeDepth:-1,
                        data: this.data,
                        top: '10px',
                        left: '30px',
                        bottom: '-20px',
                        right: '100px',
                        symbolSize: 10,
                        itemStyle: {
                            color: '#7682ce'
                        },
                        lineStyle: {
                            color: '#7682ce'
                        },
                        label: {
                            position: 'right',
                            verticalAlign: 'middle',
                            fontSize: 12,
                            formatter:(params)=>{
                                const { data } = params
                                const time = this.time
                                if(String(time) == '1'){
                                    return data.name + '  ' + (data.percentage1 || '') + '  ' + ( data.value1 || '' )
                                }else if(String(time) == '2'){
                                    return data.name + '  ' + (data.percentage2 || '') + '  ' + ( data.value2 || '' )
                                }else if(String(time) == '3'){
                                    return data.name + '  ' + (data.percentage3 || '') + '  ' + ( data.value3 || '' )
                                }else if(String(time) == '4'){
                                    return data.name + '  ' + (data.percentage4 || '') + '  ' + ( data.value4 || '' )
                                }else{
                                    return data.name + '  ' + (data.percentage || '') + '  ' + ( data.value || '' )
                                }
                            }
                        },
                        leaves: {
                            label: {
                                position: 'top',
                                verticalAlign: 'middle',
                            }
                        },
                        emphasis: {
                            focus: 'descendant'
                        },
                        expandAndCollapse: true,
                        animationDuration: 550,
                        animationDurationUpdate: 750
                    }
                ]
            }
        }
    },
    watch:{
        data:{
            handler(newValue){
                this.option.series[0].data = newValue
                this.initTreeChart()
            },
            deep:true
        },
        time:{
            handler(newValue){
                this.initTreeChart()
            },
        }
    },
    mounted() {
     this.initTreeChart()
    },
    methods: {
    // 初始化树图方法
    initTreeChart() {
        const instance = this.myChart
        if(instance != null && instance != "" && instance != undefined){ this.myChart.dispose() } //清除DOM上已经初始化的图表实例
        const myChart = this.$echarts.init(document.getElementById(this.echartsId))
        this.myChart = myChart
        const resizeDiv = document.getElementById(this.echartsId)
        myChart.setOption(this.option)
        const listener = () => { myChart.resize() }
        EleResize.on(resizeDiv, listener)
        myChart.on('click', this.treeNodeclick)
    },
    // 节点点击事件
    treeNodeclick(param) {
        this.$emit('treeClick',param)
    }
  }
}
</script>

<style scoped>
#echartsTreeModule{
    width: 100%;
    height: 100%;
}
</style>