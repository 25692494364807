<!--电能管理--设备--拓扑图--树形页面-->
<template>
  <div id="treeModule" v-loading="loading" :element-loading-text="$t('alarm.a0')">
    <!-- 树形图表 -->
    <transition name="slide-fade">
        <div v-if="istreeModule" class="treeContainer">
            <div class="tree-header">
                <a-radio-group v-model="value" >
                    <a-radio-button value="2">
                        {{ $t('topologySet.a40') }}
                    </a-radio-button>
                    <a-radio-button value="3">
                        {{ $t('topologySet.a41') }}
                    </a-radio-button>
                    <a-radio-button value="4">
                        {{ $t('topologySet.a42') }}
                    </a-radio-button>
                </a-radio-group>
            </div>
            <div class="tree-main">
                <my-echartsTreeModule :data="treeData" :echartsId="echartsId" @treeClick="treeClick" :time="value" />
            </div>
        </div>
    </transition>

    <!-- 卡片拓扑图 -->
    <transition name="slide-fade">
        <div v-if="!istreeModule" class="topologyModule">
            <!-- 头部 -->
            <div class="header">
                <my-headerTitle>
                    {{ $t('topologySet.a39') }}
                <template v-slot:name>
                    <a-icon type="close" @click="closeTopology"/>
                </template>
                </my-headerTitle>
            </div>
            <!-- 内容区域 -->
            <div class="main">
                <table border="1" v-if="topologyData.energyEquipmentNode.children.length !== 0">
                <tr v-if="topologyData.energyEquipmentRoot.children.length !== 0">
                    <th :colspan="colspan">
                        <my-card v-for="(item,index) in topologyData.energyEquipmentRoot.children" :key="index" :cardContent="item" class="card" @hasVideoChange="hasVideoChange"/>
                    </th>
                </tr>
                <tr>
                    <th class="table-title" v-for="(item,index) in topologyData.energyEquipmentNode.children" :key="index">{{item.title}}</th>
                </tr>
                <tr>
                    <th v-for="(item,index) in topologyData.energyEquipmentNode.children" :key="index">
                    <div class="table-container-calss1">
                        <my-card v-for="(a,b) in item.children" :key="b" :cardContent="a" @hasVideoChange="hasVideoChange"/>
                    </div>
                    </th>
                </tr>
                </table>
                <div v-if="topologyData.energyEquipmentNode.children.length == 0" class="card-container">
                    <my-card v-for="(item,index) in topologyData.energyEquipmentRoot.children" :key="index" :cardContent="item" @hasVideoChange="hasVideoChange"/>
                <div class="empty" v-for="n in 10" :key="'m' + n"></div>
                </div>
            </div>
            <!-- 底部 -->
            <div class="floor">
                <ul class="express">
                <li class="expressli" v-for="(item, index) in icon" :key="index">
                    <img :src="item.url" />
                    <span>{{ item.name }}</span>
                </li>
                </ul>
            </div>
        </div>
    </transition>
    <!-- 视频弹框 -->
    <camera-dialog :cameraId="cameraId" :vmode="vmode" :starDate="starDate.format('YYYY/MM/DD HH:mm:ss')" :endDate="endDate.format('YYYY/MM/DD HH:mm:ss')"></camera-dialog>
  </div>
</template>

<script>
import echartsTreeModule from './components/echartsTreeModule.vue'
import card from './components/card.vue'
import headerTitle from '../../components/Title/headerTitle.vue'
import { getTopologyInfo } from '../../api/energy'
import { getEnergyDataTreeCalculate } from '../../api/energy'
import cameraDialog from '../monitoring/cameraDialog.vue';
import moment from "moment";

export default {
    name:'treeModule',
    components: {
        'my-echartsTreeModule':echartsTreeModule,
        'my-card':card,
        'my-headerTitle':headerTitle,
        "camera-dialog":cameraDialog,
  },
  data(){
    return{
        loading:false,
        value:'2',
        echartsId:'tree1',
        treeData:[],
        colspan: 1,
        topologyData:{
                energyEquipmentNode:{
                    children:[]
                },
                energyEquipmentRoot:{
                    children:[]
                }
        },
        icon: [
                {
                    name: this.$t('doorequipment.a6'),
                    url: require("../../../public/images/door/equipment01.png"),
                },
                {
                    name: this.$t('doorequipment.a7'),
                    url: require("../../../public/images/door/equipment02.png"),
                },
                {
                    name: this.$t('doorequipment.a8'),
                    url: require("../../../public/images/door/equipment03.png"),
                },
                {
                    name: this.$t('doorequipment.a9'),
                    url: require("../../../public/images/door/equipment04.png"),
                },
                {
                    name:this.$t('doorequipment.a10'),
                    url: require("../../../public/images/door/equipment05.png"),
                },
                {
                    name: this.$t('doorequipment.a11'),
                    url: require("../../../public/images/door/equipment06.png"),
                },
        ],
        istreeModule:true,
        cameraId:0,
        vdialog: false,
        vmode:'1',
        starDate:new moment().startOf('day'),
        endDate:new moment(),
    }
  },
   // 页面树状图初始化数据加载
    mounted(){
        this.loading = true
        const siteId = this.$route.query.id
        const data = { siteId }
        getEnergyDataTreeCalculate(data)
        .then(res=>{
            console.log(res)
            const { data } = res
            this.treeData = data
            this.loading = false
        })
        .catch(err=>{
            console.log(err)
            this.loading = false
        })
    },
    methods:{
        // 树形图最后层级点击方法
        treeClick(param){
            if(param.data.gto){
                this.loading = true
                const rootId = param.data.id
                const siteId = this.$route.query.id
                const data = { rootId,siteId }
                getTopologyInfo(data)
                .then(res=>{
                    console.log(res)
                    const { data } = res
                    const class1 = data.energyEquipmentNode.children.length
                    this.colspan = class1
                    this.topologyData = data
                    this.loading = false
                    this.istreeModule = false
                })
                .catch(err=>{
                    console.log(err)
                    this.loading = false
                })
            }else{
                this.$message.warning(this.$t('topologySet.a43'))
            }
        },
        // 切换到树形图表
        closeTopology(){
            this.istreeModule = true
        },
        moment,
        // 卡片视频按钮点击回调函数
        hasVideoChange(data){
        this.vdialog = true;
        this.cameraId = data.cameraId;
        },
    }
}
</script>

<style scoped>
#treeModule{
    width: 100%;
    height: 100%;
    position: relative;
}
.tree-main{
    height: calc(100% - 32px);
}
.treeContainer{
    width: 100%;
    height: 100%;
    padding: 20px;
    position: absolute;
}
.topologyModule{
    width: 100%;
    height: 100%;
    position: absolute;
}
.header{
    padding: 0px 20px;
}
.main{
    height: calc(100% - 81px);
    padding: 20px;
    overflow: auto;
}
.table-container-calss1{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 10px 0px 10px;
}
.card{
    margin: 10px;
}
.card-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.empty {
    width: 200px;
    height: 1px;
    margin-bottom: 15px;
}
th{
    vertical-align:top;
}
.table-title{
    text-align: center;
    padding: 10px;
}
.floor {
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 20px;
}
.express {
    display: flex;
}
.expressli {
    display: flex;
    align-items: center;
    margin-right: 20px;
    white-space: nowrap;
}
ul{
    margin: 0px;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>